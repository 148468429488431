<template>
<breadcrumb  :home="home" :model="britems" />
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<!-- <Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Create New Event" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar> -->

				<DataTable ref="dt" :loading="loading2" :value="products" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="6"  responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<div class="text-3xl" style="text-align:center;color:orangered">Manage Events</div>
							<!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
						</div>
					</template>

					
					<Column field="name" header="Event Name" :sortable="true" headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Event Name</span>
							{{slotProps.data.event_name}}
						</template>
					</Column>
					<Column field="start_time" header="Start Time"  headerStyle="width:12%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Start Time</span>
							<div style="color: green;font-size: medium;font-weight: 600;">{{formatAMPM(slotProps.data.start_time)}}</div>
							<div style="margin-top: 4px;color: orangered;">{{formatDate(slotProps.data.start_time)}}</div>
						</template>
					</Column>
					<!-- <Column field="end_time" header="End Time"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">End Time</span>
							{{slotProps.data.end_time}}
						</template>
					</Column> -->
					
					<!-- <Column field="duration" header="Duration"  headerStyle="width:14%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Duration</span>
							{{(slotProps.data.duration)}}
						</template>
					</Column> -->
					
					<Column field="Admin Time" header="Host Email"  headerStyle="width:14%; min-width:8rem;">
						<template #body="slotProps">
							<span class="p-column-title">Admin Email</span>
							{{(slotProps.data.created_by)}}
						</template>
					</Column>
					<Column field="emails" header="Co Host Email"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							{{slotProps.data.co_host_by}}
						</template>
					</Column>
					<!-- <Column field="emails" header="Status"  headerStyle="width:34%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">status</span>
							{{slotProps.data.status}}
						</template>
					</Column> -->
					
					<Column headerStyle="min-width:19rem;" header="Action">
						<template  #body="slotProps">
							<Button v-if="slotProps.data.active && !slotProps.data.roster_created" :disabled="!slotProps.data.event_microsoft_id || slotProps.data.event_microsoft_id==null" label="Reponses" icon="pi pi-user" class="p-button-rounded  mr-2" @click="redirectToResponses(slotProps.data)"/>
							<Button v-if="slotProps.data.active && !slotProps.data.roster_created" label="Preview Roster" icon="pi pi-calendar" class="p-button-rounded p-button-warning mr-2" @click="redirecttoRosterConfirmation(slotProps.data)" />
							<Button v-if="slotProps.data.active && slotProps.data.roster_created" label="Roster" icon="pi pi-calendar" class="p-button-rounded p-button-warning mr-2" @click="onetooneRedirect(slotProps.data)" />
							<Button v-if="slotProps.data.active" icon="pi pi-trash" label="Cancel" class="p-button-rounded p-button-danger mr-2" @click="confirmDeleteProduct(slotProps.data)" />
							<div v-if="!slotProps.data.active">Deleted</div>
							<!-- <Button icon="pi pi-bell" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteReminder(slotProps.data)" /> -->
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to cancel the Event <b>{{product.doc_id}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = True"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-warning" @click="deleteProduct(product.id)" />
					</template>
				</Dialog>

					<Dialog v-model:visible="deleteReminderDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to send reminder<b>{{product.doc_id}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteReminderDialog = True"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="sendEmailReminder(product.event_id)" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>

				<Dialog v-model:visible="allowCreatorAsParticipant" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Would you like to be part of the roster?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-rounded p-button-warning" @click="redirecttoRosterNotAllowed"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-rounded p-button-warning" @click="redirecttoRosterAllowed" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SNService from "../service/SNService";
import  'primevue/breadcrumb';

export default {
	props: {
    name1: {
      type: String,
      default: 'Vue!'
    }
  },
	data() {
		return {
			home: {icon: 'pi pi-home', to: '/'},
            britems: [
                {label: 'Manage Events', disabled:true},
            ],
			event_id:null,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteReminderDialog: false,
			deleteProductsDialog: false,
			allowCreatorAsParticipant:false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			account: undefined,
			loading2 :true,
			selectedEvent:undefined
		}
	},
	productService: null,
	async created() {
		this.srateService = new SNService();
		this.initFilters();
	},
	mounted() {
		
		this.srateService.get(0).then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
            });	
				
	},
	methods: {
		redirectToResponses(data) {this.$router.push({ path: '/smres/'+data.id+'/'+data.event_microsoft_id });},
		redirecttoRosterConfirmation(data){
			this.selectedEvent=data;
			this.allowCreatorAsParticipant=true
		},
		onetooneRedirect(data){this.$router.push("/roster/approved/"+data.id);},
		redirecttoRosterAllowed(){
			let data = this.selectedEvent;
			this.allowCreatorAsParticipant=true;
			this.$router.push({ path: '/roster/event/'+data.id+"/"+data.event_microsoft_id+"/true" });
		},
		redirecttoRosterNotAllowed(){
			let data = this.selectedEvent;
			this.allowCreatorAsParticipant=false;
			this.$router.push({ path: '/roster/event/'+data.id+"/"+data.event_microsoft_id+"/false" });
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.product = {admin_emails:this.account.username};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		sendRoster(data){
			this.srateService.sendRosterById(data.event_id).then((res)=>{
					console.log(res);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Roster Send!', life: 3000});
              
			}).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                });
		},
		sendEmailReminder(id){
		this.deleteReminderDialog = false;
		this.submitted = true;
		console.log(this.product);
		this.srateService.sendEmailReminder(id).then((res)=>{
					console.log(res);
			this.$toast.add({severity:'success', summary: 'Reminder Successful Sent', detail: 'Deleted!', life: 3000});		
            })},
		
		
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		confirmDeleteReminder(product) {
			this.product = product;
			this.deleteReminderDialog = true;
		},
		deleteProduct(event_id) {
			this.loading2 = true;
			this.deleteProductDialog = false;
			this.srateService.cancel_event(event_id).then((res) => {
			console.log(res);
			this.srateService.get(0).then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
            });	
            });
			
			// this.products = this.products.filter(val => val.id !== this.product.id);
			// this.deleteProductDialog = false;
			// this.product = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Deleted!', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			// this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			// this.deleteProductsDialog = false;
			// this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Not Implemented!', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
		formatDate(date_string){
				let t =date_string.split('T')[0].trim().split("-");	
				const monthNames = ["January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
					];

				let year = t[0];
                let month = t[1];
                let dateNo = t[2];
			return dateNo +"-"+monthNames[month-1]+"-"+year ;
		},
		formatAMPM(date_string){				
                let t =date_string.split('T')[1].trim().split(":");				
				return t[0]+":"+t[1];
			}
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
